
import Footer from '../Footer';
import Navbar from '../Navbar';
import About from '../blog/topic3';


function App() {
  return (
    <>
      <Navbar />
      <About />
      <Footer />
    </>
  );
}

export default App;