import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import img1 from './../assets/gallery/g (1).jpeg';
import img2 from './../assets/gallery/g (2).jpeg';
import img3 from './../assets/gallery/g (3).jpeg';
import img4 from './../assets/gallery/g (4).jpeg';
import img5 from './../assets/gallery/g (5).jpeg';
import img6 from './../assets/gallery/g (6).jpeg';
import img7 from './../assets/gallery/g (7).jpeg';
import img8 from './../assets/gallery/g (8).jpeg';
import { Link } from 'react-router-dom';
import bgImg2 from '../assets/male_fertility_img_dr_1.png';
import Accordion from 'react-bootstrap/Accordion';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: '',
    imgPath:
      img1,
  },
  {
    label: '',
    imgPath:
      img2,
  },
  {
    label: '',
    imgPath:
      img3,
  },
  {
    label: '',
    imgPath:
      img4,
  },

  {
    label: '',
    imgPath:
      img5,
  },

  {
    label: '',
    imgPath:
      img6,
  },

  {
    label: '',
    imgPath:
      img7,
  },

  {
    label: '',
    imgPath:
      img8,
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
    <h1
    className=" "
    style={{
      color: '#341E93',
      marginBottom: '50px',
      position: 'relative',
      animation: 'fadeIn 1s',
    }}
  >
    Our infrastructure
    <span
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 'calc(100% + 5px)', // Adjust the distance from the text
        height: '4px',
        backgroundImage: 'linear-gradient(to right, #341E93, #E73F77)',
        backgroundSize: '10% 4px', // Set the width to 100%
        backgroundRepeat: 'no-repeat',
      }}
    />
  </h1>
  
    <Box sx={{ maxWidth: 1200, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 600,
                  display: 'block',
                  maxWidth: 900,
                  overflow: 'hidden',
                  alignItems: 'center',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    <div>
    <h1 className='male_con_sub_heading' style={{ marginLeft: "20px", marginTop: "20px"  }}>
     Best Sexologist in Bangalore
      </h1>
    <div className='grid md:grid-cols-2 gap-1 px-2 text-left who_font_style'>
      <div>
   
    <Accordion style={{marginBottom :'80px'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: '#341E93'}}>1. Premature Ejaculation</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>
        Premature Ejaculation  (PE) is a condition where a man Ejaculates sooner than he or his Partner  would like During Sexual Activity. However, when it occurs Frequently and causes Distress or Relationship Problems, it may be Diagnosed as PE. It can be caused by Physical factors such as Hormonal Imbalances or Hypersensitivity of the penis. Treatment options include Behavioral techniques and Medications.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="1">
        <Accordion.Header>2. Erectile dysfunction ED</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>
        Erectile Dysfunction (ED) is a condition Characterized by the inability to achieve or  maintain an erection  firm enough for Sexual intercourse. It can be caused by various Factors such as Physical issues, Psychological Factors, or lifestyle choices. Treatment Options include Medications ,lifestyle Changes ,and Therapy. 
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="2">
        <Accordion.Header>3. Low sperm count (oligospermia)</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Oligospermia is Low Sperm Count in Semen, Potentially causing Fertility issues. It can result from Various factors like Hormonal Imbalances or lifestyle Choices. Treatment depends on the cause and may Involve Lifestyle Changes, Medication, or Assisted Reproductive Techniques.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="3">
        <Accordion.Header>4. Low Male libido or reduced sexual desire</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Male low libido, or Hypoactive Sexual Desire Disorder (HSDD), is a Persistent lack of interest in Sexual activity causing Distress,. It can be due to Physical Factors like Hormonal Imbalances or Psychological Factors such as Stress., Treatment Depends on the cause and may Include Medication, Therapy, or lifestyle Changes.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="4">
        <Accordion.Header>5. Low testosterone levels (Hypogonadism)</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Testosterone is a hormone essential for male development, responsible for traits like muscle mass and sex drive. Levels peak in early adulthood and decline with age. Low levels can lead to symptoms like reduced libido and fatigue.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="5">
        <Accordion.Header>6. Spermatorrhea</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Spermatorrhea is the involuntary or Excessive Ejaculation of Semen without Sexual Arousal. It can happen During Sleep or waking hours and may be caused by Physical or Psychological Factors. Treatment Depends on the cause and may include Medications or Therapy.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="6">
        <Accordion.Header>7. Azoospermia </Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Azoospermia is a condition where there are no sperm present in the semen. It can be due to a blockage preventing sperm from mixing with semen or issues with sperm production in the testicles. It can lead to infertility and may require medical intervention for conception.

</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="7">
        <Accordion.Header >8. Problems with sperm movement (asthenozoospermia)</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Asthenozoospermia, also known as asthenospermia, is a condition that causes low sperm motility, which can impact fertility. It's defined as less than 40% sperm motility, or less than 32% progressive motility. Sperm motility is one of the key parameters assessed in a standard semen analysis to evaluate male fertility.</Accordion.Body>
        </Accordion.Item>
        
    </Accordion>
    
    </div>
        <div className='w-full transform hover:translate-x-2 transition duration-300 ease-in-out'style={{ marginRight: "20px", marginTop: "-60px"  }}>
        <img className='w-full' src={bgImg2} alt="/" />
      </div>
      </div>
    </div>

    <Link to="/MaleFertilityPage"> 
         <Button   style={{  marginTop: '-20px' }} ><b>View more</b></Button>{' '}
         </Link>
    </div>

  );
}

export default SwipeableTextMobileStepper;