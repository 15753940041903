import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgImg1 from '../assets/blogheropic.jpeg';
import "./BlogHero.css"


const BlogHero = () => {
  
  return (
    <div className='w-full md:h-[76vh] md:h-[69vh] flex flex-col blog_hero_bg'
    style={{ 
      backgroundImage: `url(${bgImg1})`, // Set the image as background
      backgroundSize: 'cover', // Set the size of the background image
      backgroundPosition: 'center', // Set the position of the background image
      height: "70vh"
    }}>
      <div className='max-w-[1240px] m-auto md:grid md:grid-cols-2'>
        <div className='flex flex-col justify-center items-center md:items-start px-2 py-8 md:text-left '>
          {/* <p className='text-3xl md:text-5xl'> <b style={{ color: "rgb(255 152 229)"}}>J</b>ourney <b style={{ color: "rgb(255 152 229)"}}>O</b>f </p> */}
          {/* <p className='text-3xl md:text-5xl re_hero_p_1'> ----------------------------</p> */}
          <h1 className='py-4 text-4xl md:text-6xl blog_hero_h1' style={{ color: "black" }}>
          {/* <FontAwesomeIcon icon={faMarsDouble} style={{ color: "#bc00a3"  }}/> */}
          Navigating Fertility Challenges</h1>
          {/* <p className='text-xl md:text-3xl blog_hero_p_1 mt-12'>
          Fertility hospitals differ in treatments, success rates, and support. Assessing reputation,
           success rates, technology, and emotional support guides your choice for building a family.
          </p> */}
        </div>
        <div className='md:relative'>
          {/* <div className='w-full transform hover:-translate-x-3 hover:-translate-y-2 transition duration-300 ease-in-out'>
            <img className='w-full' src={bgImg1} alt="/" style={{ borderRadius: "20px"  }}/>
          </div> */}
      </div>
    </div>
    </div>
  );
};



export default BlogHero;