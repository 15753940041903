import Footer from '../Footer';
import Navbar from '../Navbar';
import Male from '../blog/topic1';



function App() {
  return (
    <>
      <Navbar />
      <Male/>
      <Footer />
    </>
  );
}

export default App;