import AHero from './../AboutHero';
import WhoWeAre from './../WhoWeAre';
import Footer from './../Footer';
import Navbar from './../Navbar';


function App() {
  return (
    <>
      <Navbar />
      <AHero />
      <WhoWeAre />
      <Footer />
    </>
  );
}

export default App;