import BlogHero from './../BlogHero';
import BlogCards from './../BlogCards';
import Footer from './../Footer';
import Navbar from './../Navbar';



function App() {
  return (
    <>
      <Navbar />
      <BlogHero />
      <BlogCards />
     
      <Footer />
    </>
  );
}

export default App;