import Navbar from './../Navbar';
import Hhero from './../hhero';
import Cards from './../cards';
import Card1 from './../card1';
import Acc from './../accordion';
import StarRating from './../StarRating';
import CustomerReview from './../CustomerReview';
//import Imgcard from './../components/imgcard';
import Gallery from './../newgallery';
import Footer from './../Footer';
import Aboutsection from './../aboutsection';
import React from "react";

const Home = () => {
    return(
        <div>
            <Navbar/>
            <Hhero/>
            <Cards/>
            <Aboutsection/>
            <Card1/>
            <Acc/>
           <Gallery/>
           <StarRating />
            <CustomerReview/>
            
            <Footer/>
           
        
        </div>
    )
}

export default Home;