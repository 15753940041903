import React from 'react';

const HeroSection = () => {
  const heroStyle = {
    position: 'relative',
    height: '400px', // Set your desired height
    background: 'url("https://img.freepik.com/free-photo/healthcare-workers-preventing-virus-quarantine-campaign-concept-beautiful-smiling-asian-doctor-nurse_1258-86278.jpg?w=1060&t=st=1702104289~exp=1702104889~hmac=96dc60c431fc0d10f5c6fc2c9b39f41c0915ddb7a11eaebf692e23152c3191d4") center/cover', // Replace with your image URL
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    
  };

  const titleStyle = {
    fontSize: '3rem', // Set your desired font size
    marginBottom: '20px',
  };

  return (
    <div style={heroStyle}>
      <h1  style={{ color: '#341E93', marginTop: '80px',marginBottom: '80px', position: 'relative', animation: 'fadeIn 1s' }}>
      Online Consultation Form
      <span
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 'calc(100% + 5px)', // Adjust the distance from the text
          height: '4px',
          backgroundImage: 'linear-gradient(to right, #341E93, #E73F77)',
          backgroundSize: '10% 4px', // Set the width to 100%
          backgroundRepeat: 'no-repeat',
        }}
      />
    </h1>
      {/* Add any additional content or components */}
    </div>
  );
};

export default HeroSection;
