import Footer from '../Footer';
import Navbar from '../Navbar';
import Topic6 from '../blog/topic6';


function App() {
  return (
    <>
      <Navbar />
      <Topic6 />
      <Footer />
    </>
  );
}

export default App;