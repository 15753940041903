import React from 'react';

const GoogleMapsEmbed = () => {
  return (
    <div>
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.593728410667!2d77.6121730751799!3d12.997816987319924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17909edb8b1b%3A0x354708e2b4fcbde2!2sNewHope%20Infertility!5e0!3m2!1sen!2sin!4v1702280160217!5m2!1sen!2sin"
        width="100%"
        height="600"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMapsEmbed;
