import MaleFertilityHero from '../MaleFertilityHero';
import Footer from '../Footer';
import Navbar from '../Navbar';
import MaleFertility from '../MaleFertilityContent';


function App() {
  return (
    <>
      <Navbar />
      <MaleFertilityHero />
      <MaleFertility />
      <Footer />
    </>
  );
}

export default App;