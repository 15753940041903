import FemaleFertilityHero from '../FemaleFertilityHero';
import Footer from '../Footer';
import Navbar from '../Navbar';
import FemaleFertility from '../FemaleFertilityContent';


function App() {
  return (
    <>
      <Navbar />
      <FemaleFertilityHero />
      <FemaleFertility />
      <Footer />
    </>
  );
}

export default App;