
import Footer from '../Footer';
import Navbar from '../Navbar';
import Topic4 from '../blog/topic4';


function App() {
  return (
    <>
      <Navbar />
      <Topic4 />
      <Footer />
    </>
  );
}

export default App;