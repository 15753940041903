import ContactHero from './../ContactHero';
import NewContactPage from './../NewContactPage';
import Footer from './../Footer';
import Navbar from './../Navbar';
import Map2 from './../map2';


function App() {
  return (
    <>
      <Navbar />
      <ContactHero />
      <NewContactPage />
      <Map2/>
      <Footer />
      
    </>
  );
}

export default App;