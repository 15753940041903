import Footer from '../Footer';
import Navbar from '../Navbar';
import Topic7 from '../blog/topic7';


function App() {
  return (
    <>
      <Navbar />
      <Topic7 />
      <Footer />
    </>
  );
}

export default App;