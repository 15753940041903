import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgImg1 from '../assets/team_doctors_2.png';
import "./AboutHero.css"

const Hero = () => {
  // const gradientStyle = {
  //   position: 'relative',
  //   display: 'inline-block',
  // };

  // const pseudoElementStyle = {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '100%',
  //   mixBlendMode: 'multiply',
  //   backgroundImage: 'linear-gradient(to right, hsl(205.16deg, 100%, 93.92%), hwb(0deg 100% 0%))',
  // };
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   responsive: [
  //     {
  //       breakpoint: 768, // Tablet breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       }
  //     },
  //     {
  //       breakpoint: 480, // Mobile breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       }
  //     }
  //   ]
  // };

  return (
    <div className='w-full md:h-[76vh] md:h-[69vh] flex flex-col'
    style={{ backgroundImage : "linear-gradient(to right, hsl(205.16deg 100% 93.92%), hwb(0deg 100% 0%))"}}>
      <div className='max-w-[1240px] m-auto md:grid md:grid-cols-2'>
        <div className='flex flex-col justify-center items-center md:items-start px-2 py-8 md:text-left'>
          {/* <p className='text-3xl md:text-5xl'> <b style={{ color: "rgb(255 152 229)"}}>J</b>ourney <b style={{ color: "rgb(255 152 229)"}}>O</b>f </p> */}
          <p className='text-3xl md:text-5xl a_hero_p_1'> Journey of </p>
          <h1 className='py-3 text-4xl md:text-6xl font-bold a_hero_h1'>Newhope Infertility Clinic</h1>
          <p className='text-xl md:text-3xl a_hero_p_1'>"Transforming Dreams into Reality"</p>
        </div>
        <div className='md:relative'>
          <div className='w-full transform hover:-translate-x-2 hover:-translate-y-2 transition duration-300 ease-in-out'>
            <img className='w-full' src={bgImg1} alt="/" />
          </div>
      </div>
    </div>
    </div>
  );
};



export default Hero;


//pink color: rgb(255 181 236);
//light pink color: "rgb(255 152 229)"
// hero background color: bg-blue-100

// style={{ color: "rgb(255 152 229)"}}
// hover move left  right

{/* <div className='md:relative'>
<div className='w-full transform hover:translate-x-2 transition duration-300 ease-in-out'>
  <img className='w-full' src={bgImg1} alt="/" />
</div>
</div> */}



{/* <div className='md:relative'> */}
{/* <Slider {...settings}> */}
  // <div>
  //   <img className='w-full' src={bgImg1} alt="/" />
  // </div>
  {/* <div>
    <img className='w-full h-[50vh] object-cover md:h-auto md:object-contain md:w-full md:mt-8' src={bgImg1} alt="/" />
  </div>
  <div>
    <img className='w-full h-[50vh] object-cover md:h-auto md:object-contain md:w-full md:mt-8' src={bgImg1} alt="/" />
  </div> */}
{/* </Slider> */}
// </div>

{/* <div name='home' className='w-full sm:h-[76vh] bg-blue-100 md:h-[69vh] mt-[42px] flex flex-col justify-between'>
<div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
    <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
        <p className='text-5xl'> ABOUT </p>
        <h1 className='py-3 text-5xl md:text-7xl font-bold' style={{ color: "rgb(255 152 229)"}}>New Hope Infertility Hospital</h1>
        <p className='text-2xl'>"Transforming Dreams into Reality"</p>

    </div>  */}