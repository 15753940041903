import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function AllCollapseExample() {
  return (
    <div>
        <div style={{ overflowY: 'auto', padding: '20px' }}>
      <h1 className="" style={{ color: '#341E93', marginTop: '80px', position: 'relative' }}>
        Why choose newhope infertility
        <span
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 'calc(100% + 5px)',
            height: '4px',
            backgroundImage: 'linear-gradient(to right, #341E93, #E73F77)',
            backgroundSize: '10% 4px',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </h1></div>
  
   <h5 className="text-center" style={{ color: '#E73F77', marginTop: '20px' }}>Choosing the right fertility clinic is a significant decision on your journey to parenthood.</h5>
  <p className="text-center"  style={{ marginBottom: '50px' }}>At Hopeful Beginnings Infertility Clinic, we stand apart as a beacon of hope and excellence, offering a unique blend of qualities that set us apart. Discover the reasons why hopeful parents choose us for their fertility care.</p>
    <Accordion style={{marginBottom :'80px'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: '#341E93'}}>1. Expertise and Experience</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>
        Our team comprises board-certified fertility specialists with extensive experience in reproductive medicine. Each member brings a wealth of expertise, ensuring that you receive the highest standard of care from professionals who are leaders in the field.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="1">
        <Accordion.Header>2. State-of-the-Art Facilities</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>
        Experience fertility treatments in a modern and advanced clinic environment. Our state-of-the-art facilities are equipped with the latest technologies, providing a conducive setting for comprehensive diagnostics and cutting-edge reproductive procedures.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="2">
        <Accordion.Header>3. Compassionate Care</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>We understand that the fertility journey is not just a medical process but an emotional one. Our team is dedicated to providing compassionate care, offering a supportive and understanding environment throughout every step of your fertility treatment.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="3">
        <Accordion.Header>4. Personalized Treatment Plans</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>No two fertility journeys are the same. At Hopeful Beginnings, we believe in personalized treatment plans tailored to your unique needs. Our specialists carefully assess your situation and craft individualized strategies to optimize your chances of success.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="4">
        <Accordion.Header>5. Success Stories</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Our success is measured by the stories of families we've helped bring together. Join a community of individuals and couples who have achieved successful outcomes on their path to parenthood with the guidance and support of Hopeful Beginnings.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="5">
        <Accordion.Header>6. Holistic Approach</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>We embrace a holistic approach to fertility care, addressing not only the physical aspects but also the emotional well-being of our patients. Our services include counseling and support, recognizing the importance of mental health during the fertility journey.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="6">
        <Accordion.Header>7. Transparent Communication</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Communication is key. We believe in transparent and open communication with our patients. Our team is dedicated to keeping you informed at every stage, providing clarity on procedures, expectations, and potential outcomes.

</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="7">
        <Accordion.Header >8. Your Partner in Parenthood</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Beyond being a healthcare provider, we see ourselves as your partners in parenthood. Our commitment goes beyond medical treatments; we are here to support, guide, and empower you on the journey to building your family.</Accordion.Body>
        </Accordion.Item>
        
    </Accordion>
    
    </div>
  );
}

export default AllCollapseExample;