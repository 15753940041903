import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { AiTwotonePhone } from 'react-icons/ai';
import logo from './../assets/slides/Screenshot (26).png';
// import { FaMale, FaFemale, FaPlus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMars, faVenus, faCirclePlus, faPhone } from '@fortawesome/free-solid-svg-icons';

const NavbarContainer = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 20px;
  }

  a {
    text-decoration: none;
    color: #341e93;
    position: relative;
    transition: background-color 1s ease;

    &:hover {
      background-color: white;
      text-decoration: none;
      color: #e73f77;
      border-bottom: 5px solid #e73f77;
    }

    transition: border-bottom 0.3s ease;
  }

  .icon {
    font-size: 24px;
  }

  img {
    height: 60px; /* Adjust the height as needed */
  }

  .bold-text {
    font-weight: bold;
    position: relative;
    transition: transform 0.3s ease;
  }

  .bold-text:hover {
    transform: translateY(4px);
  }

  .toggle-menu {
    display: none;
    cursor: pointer;
    font-size: 24px;
    z-index: 3;
  }

  .close-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #341e93;
    z-index: 3;
  }



  @media (max-width: 768px) {
    .toggle-menu {
      display: block;
    }

    ul {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      position: absolute;
      top: 70px;
      left: 0;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 3;
    }

    ul.show {
      display: flex;
      align-items: center;
    }

    li {
      margin: 10px 0;
    }
    ul ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 3;
      width: 100%; /* Set width to cover the entire screen */
      padding: 10px; /* Adjust padding as needed */
    }

    li:hover > ul {
      display: flex; /* Change the display to flex to ensure proper layout */
      flex-direction: column; /* Align dropdown items in a column */
      width: 195px; /* Cover the entire width */
    }

    ul ul li {
      width: 100%; /* Set the width to cover the entire dropdown */
      padding: 2px;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      gap: 1px;
      transition: background-color 0.3s ease;
    }
  }

  li {
    position: relative; /* Set 'position: relative' for list items */
  }
  ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }

  li:hover > ul {
    display: block;
  }

  ul ul li {
    width: 200px;
    padding: 2px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    gap: 2px;
    transition: background-color 0.3s ease;
  }

  ul ul li:hover {
    background-color: #f9f9f9;
  }
`;

const App = () => {
  const [inProp, setInProp] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showTreatmentsDropdown, setShowTreatmentsDropdown] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleTreatmentsDropdown = () => {
    setShowTreatmentsDropdown(!showTreatmentsDropdown);
  };

  return (
    <NavbarContainer>
      <img src={logo} alt="Logo" />

      <CSSTransition in={inProp} timeout={300} classNames="navbar" unmountOnExit>
        <ul className={menuOpen ? 'show' : ''}>
          <li>
            <Link to="/" onClick={() => setInProp(true)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setInProp(true)}>
              About
            </Link>
          </li>

          <li onClick={toggleTreatmentsDropdown}>
          <Link>
            Treatments
            </Link>
            {showTreatmentsDropdown && (
              <ul>
                <li>
                  <FontAwesomeIcon icon={faMars} />
                  <Link to="/MaleFertilityPage">MaleFertility</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faVenus} />
                  <Link to="/FemaleFertilityPage">FemaleFertility</Link>
                </li>
                
              </ul>
            )}
          </li>
          <li>
            <Link to="/BlogPage" onClick={() => setInProp(true)}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/contactus" onClick={() => setInProp(true)}>
              Contact
            </Link>
          </li>
          <li>
            <Link to="/onlineconsultation" onClick={() => setInProp(true)}>
            Online consulting
            </Link>
          </li>
          
          <li className="bold-text">
          <FontAwesomeIcon icon={faPhone} />
            Phone: +91 9952233808
          </li>
        </ul>
      </CSSTransition>

      {menuOpen && <div className="close-icon" onClick={closeMenu}>&times;</div>}
      <div className="toggle-menu" onClick={toggleMenu}>
        ☰
      </div>
    </NavbarContainer>
  );
};

export default App;
