import Footer from '../Footer';
import Navbar from '../Navbar';
import Topic11 from '../blog/topic11';


function App() {
  return (
    <>
      <Navbar />
      <Topic11 />
      <Footer />
    </>
  );
}

export default App;