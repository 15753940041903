import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './../src/components/pages/Home';
import MaleFertilityPage from './components/pages/MaleFertilityPage';
import FemaleFertilityPage from './components/pages/FemaleFertilityPage';
import BlogPage from './components/pages/BlogPage';
import ContactUs from './components/pages/ContactUs';
import './index.css';
import About from './components/pages/About';
import Blogmale from './components/blogpages/topic1';
import Blogfemale from './components/blogpages/topic2';
import Blogabout from './components/blogpages/topic3';
import Consult from './components/pages/consult';
// import ScrollToTop from './components/ScrollToTop';
import Topic4 from './components/blogpages/topic4';
import Topic5 from './components/blogpages/topic5';
import Topic6 from './components/blogpages/topic6';
import Topic7 from './components/blogpages/topic7';
import Topic8 from './components/blogpages/topic8';
import Topic9 from './components/blogpages/topic9';
import Topic10 from './components/blogpages/topic10';
import Topic11 from './components/blogpages/topic11';

function App() {
  return (
    <Router>
    {/* <ScrollToTop/> */}
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/MaleFertilityPage" element={<MaleFertilityPage />} />
        <Route path="/FemaleFertilityPage" element={<FemaleFertilityPage />} />
        {/* <Route path="/AdditioonalFertilityPage" element={<AdditioonalFertilityPage />} /> */}

        <Route path="/BlogPage" element={<BlogPage />} />
        <Route path="/onlineconsultation" element={<Consult />} />
        <Route path="/erectile-dysfunction-and-treatment-in-bangalore" element={<Blogmale />} />
        <Route path="/male-erectile-dysfunction-treatment-in-bangalore" element={<Blogfemale />} />
        <Route path="/erectile-impotence-treatment-in-bangalore" element={<Blogabout />} />
        <Route path="/best-treatment-for-pre-ejaculation-in-bangalore" element={<Topic4 />} />
        <Route path="/sexologist-in-bangalore" element={<Topic5 />} />
        <Route path="/best-fertility-doctor-in-bangalore" element={<Topic6 />} />
        <Route path="/walk-in-sexual-health-clinic-in-bangalore" element={<Topic7 />} />
        <Route path="/male-fertility-clinic-in-bangalore" element={<Topic8 />} />
        <Route path="/sexologist-doctors-near-me-in-bangalore" element={<Topic9 />} />
        <Route path="/male-sexual-dysfunction-treatment-in-bangalore" element={<Topic10 />} />
        <Route path="/ayurvedic-sexologist-near-me-in-bangalore" element={<Topic11 />} />
      </Routes>
    </Router>
  );
}
export default App;
