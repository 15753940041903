import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import img101 from './../assets/slides/img101.jpg';

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  margin-right: 20px;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
`;

const TextColumn = styled.div`
  flex: 1;

  h5 {
    color: #E73F77;
  }

  h3 {
    color: #E73F77;
  }

  p {
    color: #555;
  }
`;

const ResponsiveSection = () => {
  return (
    <SectionContainer>
      <ImageColumn>
        <img src={img101} alt="Description" />
      </ImageColumn>
      <TextColumn>
      <h1 className="" style={{ color: '#341E93', marginBottom: '50px', position: 'relative', animation: 'fadeIn 1s' }}>
      About newhope 
      <span
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 'calc(100% + 5px)', // Adjust the distance from the text
          height: '4px',
          backgroundImage: 'linear-gradient(to right, #341E93, #E73F77)',
          backgroundSize: '10% 4px', // Set the width to 100%
          backgroundRepeat: 'no-repeat',
        }}
      />
    </h1>
        <h5>Hopeful beginnings infertility clinic</h5>
        <p>
        Welcome to Newhope Infertility Clinic, where we specialize in providing compassionate and comprehensive care for individuals and couples facing fertility challenges. Our dedicated team of fertility experts, cutting-edge technology, and personalized approach aim to guide you on the journey to parenthood.
        </p>
        <h5>Our mission</h5>
        <p>At Hopeful Beginnings, our mission is to empower individuals and couples with the knowledge, support, and advanced fertility treatments needed to achieve their dream of building a family. We understand the emotional and physical challenges associated with infertility, and our commitment is to provide a path to hope, healing, and successful outcomes.</p>
        <Link to="/about"> 
         <Button   style={{  marginTop: '20px' }} >See more</Button>{' '}
         </Link>
      </TextColumn>
     
    </SectionContainer>
  );
};

export default ResponsiveSection;
