import Footer from './../Footer';
import Navbar from './../Navbar';
import Consult from './../consultform';
import Consulthero from './../consulthero';

function App() {
  return (
    <>
      <Navbar />
      <Consulthero/>
      <Consult/>
      <Footer />
    </>
  );
}

export default App;