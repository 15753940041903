
import Footer from '../Footer';
import Navbar from '../Navbar';
import Female from '../blog/topic2';


function App() {
  return (
    <>
      <Navbar />
      <Female />
      <Footer />
    </>
  );
}

export default App;