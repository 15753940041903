// src/components/CarouselComponent.js
import React from 'react';
import { Carousel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import Slide3 from './../assets/slides/slide21.jpg';
import { Link } from 'react-router-dom';

const StyledCarouselCaption = styled.div`
  h3 {
    font-size: 36px;
     /* Set your desired font size */
  }

  p {
    font-size: 18px;
    /* Set your desired font size */
  }

  

  /* Media query for smaller screens */
  @media (max-width: 760px) {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    text-align: center;

    h3 {
      font-size: 14px;
      margin-top: 10px;
      /* Set a smaller font size for small screens */
    }

    p {
      display: none;
      font-size: 14px;
      margin-bottom: 10px;
       /* Set a smaller font size for small screens */
    }

    button {
     
      font-size: 8px; /* Set your desired font size */
      padding: 2px 4px;
       /* Adjust padding to reduce button size */
    }
  }
  /* Media query for medium screens */
@media (min-width: 761px) and (max-width: 1024px) {
  /* Styles for medium screens */
  
  position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    transform: translateY(-80%);
    text-align: center;

    h3 {
      font-size: 18px;
      margin-top: 50px;
   /* Set a smaller font size for small screens */
    }

    p {
      
      font-size: 14px;
      margin-bottom: 15px; 
      /* Set a smaller font size for small screens */
    }

    button {
     
      font-size: 8px; /* Set your desired font size */
      padding: 2px 4px;
       /* Adjust padding to reduce button size */
    }


}
`;

const CarouselComponent = () => {

    const [titleRef, titleInView] = useInView({
        triggerOnce: false,
      });

      
    
      const [missionRef, missionInView] = useInView({
        triggerOnce: false,
      });
    
      const [visionRef, visionInView] = useInView({
        triggerOnce: false,
      });
    
      const animationVariants = {
        hidden: { opacity: 1, y: -50 },
        visible: { opacity: 1, y: 0 },
      };
    
  
      const animationTransition = {
        duration: 1,
      };

      const animationTransition1 = {
        duration: 1.5,
      };
      
      
  return (
    <Carousel fade  data-bs-theme="dark" >
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide3}
          alt="Third slide"
        />
        <Carousel.Caption  className="text-dark text-center position-absolute top-50 ">
            {/*text-dark text-center position-absolute top-50  left-100 start-0 translate-x-minus-0*/} 
            <motion.div
            ref={titleRef}
            initial="hidden"
            animate={titleInView ? 'visible' : 'hidden'}
            variants={animationVariants}
            transition={animationTransition}
          >
            <StyledCarouselCaption>
          <h3 className='h3'  >NewHope Infertility</h3> {/*style={{ color: '#E73F77'}}*/ }
          
          
          <p className="transition-p">Comprehensive care, individualized solutions, nurturing the family dream.</p>

          <Link to="/about">
      <Button style={{ backgroundColor: '#343a40', borderColor: '#343a40' }} variant="dark">
        Learn more
      </Button>
    </Link>

          </StyledCarouselCaption>
          </motion.div>
          
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src='https://img.freepik.com/premium-photo/indian-couple-celebrating-positive-pregnancy-test-sitting-home-husband-embracing-wife-awaiting-baby_176841-27649.jpg?w=1060'
          alt="Second slide"
        />
        <Carousel.Caption className="text-dark text-center position-absolute top-50 start-50   translate-x-minus-50">
        <motion.div
                ref={missionRef}
                initial="hidden"
                animate={missionInView ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={animationTransition}
              >
                <StyledCarouselCaption>
          <h3  >Family Dreams Fulfilled</h3>
          <p>Transforming aspirations into cherished family moments with compassion.</p>
          </StyledCarouselCaption>
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src='https://img.freepik.com/premium-photo/content-pregnant-woman-smiling-caressing-belly-pointing-white-background-enjoying-pregnancy-anticipating-motherhood_176841-18476.jpg?w=1060'
          alt="First slide"
        />
        <Carousel.Caption style={{ left: '50%' }} className="text-dark text-center position-absolute top-50  ">
        <motion.div
                ref={visionRef}
                initial="hidden"
                animate={visionInView ? 'visible' : 'hidden'}
                variants={animationVariants}
                transition={animationTransition}
              >
                <StyledCarouselCaption>
          <h3 className='display-flex'>Embrace Life Together</h3>
          <p>Expert care, personalized paths, embracing your family journey.</p>
          </StyledCarouselCaption>
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>

      
    </Carousel>
  );
};

export default CarouselComponent;
