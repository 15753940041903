import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Logo1 from './../assets/slides/Preventive Health Care (1).gif'; // Import your logo images
import Logo2 from './../assets/slides/Corona Vaccine.gif';
import Logo3 from './../assets/slides/Mental Therapy.gif';
import Button from 'react-bootstrap/Button';

function SmallSection() {
  const sectionStyle = {
    backgroundColor: '#f2f2f2', // Grey background color
    padding: '20px', // Adjust the padding as needed
  };

  const arrowStyle = {
    // Use linear gradient for the color
    color: 'linear-gradient(to right, #341E93, #E73F77)',

    // Set additional styles as needed
    fontSize: '32px',
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
  };

  return (
    <div style={sectionStyle}>
      <Container>
        <Row xs={1} md={1} lg={3} className="g-4">
          {/* Card 1 */}
          <Col>
            <Card>
              <Card.Img variant="left" src={Logo1} />
              <Card.Body>
                <Card.Title style={{ color: '#341E93' }}>Our Doctors</Card.Title>
                <Card.Text style={{ color: '#555' }}>
                Dr. Riayz & his team brings a wealth of experience and expertise in reproductive medicine. As a
                  board-certified fertility specialist, he is committed to helping individuals and couples navigate the
                  complexities of infertility.
                </Card.Text>
                <div>
                  {/* Apply the styles and onClick event to the FaArrowRight component */}
                  <a href="/about">
      <Button style={{ backgroundColor: '#343a40', borderColor: '#343a40' ,alignItems: 'center' }} variant="dark">
        Learn more
      </Button>
    </a>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 2 */}
          <Col>
            <Card>
              <Card.Img variant="left" src={Logo2} />
              <Card.Body>
                <Card.Title style={{ color: '#341E93' }}>Our Treatment</Card.Title>
                <Card.Text style={{ color: '#555' }}>
                  Our dedicated team of specialists combines cutting-edge medical technologies with compassionate care
                  to design customized treatment approaches tailored to your individual needs and the way for your
                  journey to parenthood.
                </Card.Text>
                <div>
                  {/* Apply the styles and onClick event to the FaArrowRight component */}
                  <a href="/MaleFertilityPage">
                  <Button style={{ backgroundColor: '#343a40', borderColor: '#343a40' ,alignItems: 'center' }} variant="dark">
        Learn more
      </Button>
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 3 */}
          <Col>
            <Card>
              <Card.Img src={Logo3} />
              <Card.Body>
                <Card.Title style={{ color: '#341E93' }}>Contact Us</Card.Title>
                <Card.Text style={{ color: '#555' }}>
                  Schedule a consultation with one of our fertility specialists. Our team is ready to provide
                  personalized guidance, answer your questions, and outline a customized treatment plan designed with
                  your success in mind.
                </Card.Text>
                <div>
                  {/* Apply the styles and onClick event to the FaArrowRight component */}
                  <a href="/contactus">
                  <Button style={{ backgroundColor: '#343a40', borderColor: '#343a40' ,alignItems: 'center' }} variant="dark">
        Learn more
      </Button>
      </a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SmallSection;
