import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import img1 from './../assets/slides/card2 (2).jpg';
import img2 from './../assets/slides/card2 (1).jpg';
import img3 from './../assets/slides/card2 (3).jpg';

function GridExample() {
  const cardData = [
    {
      image: img1,
      title: 'Male Infertility',
      content: 'Embark on a journey to understand and enhance male fertility. Discover the pivotal role of sperm health, hormonal balance, and reproductive wellness. From lifestyle adjustments to advanced medical interventions, empower your reproductive potential with personalized care and expert guidance on the path to fatherhood.',
      learnMoreLink: '/MaleFertilityPage',
    },
    {
      image: img2,
      title: 'Female Infertility',
      content: 'Explore the intricate world of female fertility and unlock the possibilities of motherhood. Delve into the dynamics of reproductive health, menstrual cycles. Our comprehensive approach embraces personalized care, advanced technologies, and support, guiding you towards the fulfilling journey of conceiving and nurturing new life.',
      learnMoreLink: '/FemaleFertilityPage',
    },
    {
      image: img3,
      title: 'Other Infertility',
      content: 'Explore tailored solutions and support for conditions like endometriosis, polycystic ovary syndrome (PCOS), reproductive disorders, and unexplained infertility. Our dedicated team stands by you, providing expertise, understanding, and a pathway to overcome unique fertility challenges on your quest for parenthood.',
      learnMoreLink: '/MaleFertilityPage',
    },
  ];

  const [hoverStates, setHoverStates] = useState(cardData.map(() => false));

  const handleMouseOver = (index) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = true;
    setHoverStates(newHoverStates);
  };

  const handleMouseOut = (index) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = false;
    setHoverStates(newHoverStates);
  };

  const getCustomCardStyle = (isHovered) => ({
    display: 'flex',
    margin: '20px',
    boxShadow: isHovered ? '0 8px 16px rgba(0, 0, 0, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s ease',
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
    border: isHovered ? '1px solid #3366cc' : '1px solid transparent',
  });

  return (
    <div style={{ overflowY: 'auto', padding: '20px' }}>
      <h1 className="" style={{ color: '#341E93', marginTop: '80px', position: 'relative', animation: 'fadeIn 1s' }}>
        <b>Sexologist in Bangalore</b>
        <span
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 'calc(100% + 5px)',
            height: '4px',
            backgroundImage: 'linear-gradient(to right, #341E93, #E73F77)',
            backgroundSize: '10% 4px',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </h1>
      
      <h5 className="text-center" style={{ color: '#E73F77', marginTop: '30px', animation: 'fadeIn 1s' }}>
        Comprehensive Fertility Treatments for Your Journey to Parenthood
      </h5>
      <p className="text-center">
        We offer a range of advanced treatments designed to address diverse fertility challenges, empowering individuals
        and couples to realize their dream of building a family.
      </p>
      <div>
        <Row xs={1} md={1} lg={3} className="g-4">
          {cardData.map((card, idx) => (
            <Col key={idx}>
              <Card
                style={getCustomCardStyle(hoverStates[idx])}
                onMouseOver={() => handleMouseOver(idx)}
                onMouseOut={() => handleMouseOut(idx)}
              >
                <Card.Img variant="top" src={card.image} />
                <Card.Body>
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>{card.content}</Card.Text>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item></ListGroup.Item>
                  </ListGroup>
                  <Card.Footer>
                    <Card.Link href={card.learnMoreLink}>Learn More</Card.Link>
                    <Card.Link href="/onlineconsultation">Book Appointment</Card.Link>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default GridExample;
