import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgImg1 from '../assets/a_hero_bg_4.png';
import "./FemaleFertilityHero.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMarsDouble } from '@fortawesome/free-solid-svg-icons';

const FemaleFertilityHero = () => {

  return (
    <div className='w-full md:h-[76vh] md:h-[69vh] flex flex-col research_hero_height'
    style={{ backgroundImage : "linear-gradient(to right, hsl(205.16deg 100% 93.92%), hwb(0deg 100% 0%))" , height: "60vh"}}>
      <div className='max-w-[1240px] m-auto md:grid md:grid-cols-2'>
        <div className='flex flex-col justify-center items-center md:items-start px-2 py-8 md:text-left'>
          {/* <p className='text-3xl md:text-5xl'> <b style={{ color: "rgb(255 152 229)"}}>J</b>ourney <b style={{ color: "rgb(255 152 229)"}}>O</b>f </p> */}
          {/* <p className='text-3xl md:text-5xl re_hero_p_1'> ----------------------------</p> */}
          <h1 className='py-4 text-4xl md:text-6xl female_hero_h1'>
          {/* <FontAwesomeIcon icon={faMarsDouble} style={{ color: "#bc00a3"  }}/> */}
           Female Fertility Treatments</h1>
          {/* <p className='text-xl md:text-3xl female_hero_p_1'>-----------------------------</p> */}
        </div>
        <div className='md:relative'>
          <div className='w-full transform hover:-translate-x-3 hover:-translate-y-2 transition duration-300 ease-in-out'>
            <img className='w-full' src={bgImg1} alt="/" style={{ borderRadius: "20px"  }}/>
          </div>
      </div>
    </div>
    </div>
  );
};



export default FemaleFertilityHero;