import React from 'react';


const Footer = () => {
   
  return (
    <div>
        <footer style={{
      background: 'linear-gradient(to top, #341E93, #E73F77)',
      padding: '10px',
      color: 'white',
      textAlign: 'center',
    }}>
    <div className="container mt-5">
    <div className="row">
      {/* First Column */}
      <div className="col-md-4">
        <h4 className='underline'style={{color:'#000000'}}>Newhope Infertility Clinic</h4>
        
        <p>We understand the emotional and physical challenges associated with infertility, and our commitment is to provide a path to hope, healing, and successful outcomes.</p>
      </div>

      {/* Second Column */}
      <div className="col-md-4" >
        <h4 className='underline' style={{color:'#000000' ,marginLeft:'30px'}}>Links</h4>
        <ul>
        <li><a href="\about">About</a></li>
          <li><a href="\MaleFertilityPage">Male fertility</a></li>
          <li><a href="\FemaleFertilityPage">Female fertility</a></li>
          <li><a href="\contactus">Contact us</a></li>
          <li><a href="\BlogPage">Blogs</a></li>
          <li><a href="\onlineconsultation">Online consultation</a></li>
        </ul>
      </div>

      {/* Third Column */}
      <div className="col-md-4">
        <h4  className='underline' style={{color:'#000000'}}>Contact Information</h4>
        <p>Address: 16/4, Mosque Rd, Pulikeshi Nagar, Bengaluru, Karnataka 560005.</p>
        <p>Email: newhopeinfertility@gmail.com</p>
        <p>Phone: +91 9952233808</p>
        <p>Mobile: +91 9942425565</p>
      </div>
    </div>
  </div>

  <h1 className="" style={{ color: '#341E93', marginBottom: '0px', position: 'relative' }}>
     
      <span
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0, // Adjust the distance from the text
          height: '4px',
          backgroundImage: 'linear-gradient(to right, #E73F77, #E73F77)',
          backgroundSize: '100% 1px', // Set the width to 100%
          backgroundRepeat: 'no-repeat',
        }}
      />
    </h1>
      <p className="" style={{  marginTop: '30px', position: 'relative' }} >&copy; 2023 newhopeinfertility.com . All rights reserved. Designed and Developed by <a href="https://madprotek.com/" target="_blank">MadProTek IT Solutions.</a></p>
    </footer>
    </div>
  );
};

export default Footer;
