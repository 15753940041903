import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import bgImg1 from '../assets/female_fertility.jpg';
import bgImg2 from '../assets/male_fertility.jpg';
import bgImg3 from '../assets/doctor_img.jpg';
import "./BlogCards.css"




export default function MediaCard() {
  return (
    <div className='text-center' style={{marginBottom:'80px'}}>
    <h1 className='blog_sub_heading text-left'style={{ marginLeft: "25px"  }}>
    Patient's Feedback
    </h1>
    <div className='grid md:grid-cols-3 gap-12 px-2'style={{ marginLeft: "0px", marginTop: "30px"  }}>

    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        
        image={'https://img.freepik.com/premium-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-872.jpg?size=626&ext=jpg&ga=GA1.1.1053072546.1708775690&semt=ais'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Zameer Khan
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Our visit to address erectile dysfunction with Dr.Riyaz was exceptionally positive. He dedicated ample time to thoroughly discuss our concerns, ensuring we felt heard and understood. The consultation left us feeling incredibly content and satisfied, especially considering the significant improvements we've noticed since.
        </Typography>
        <p>   </p>
      </CardContent>
      <CardActions>
       
      </CardActions>
    </Card>



    <Card sx={{ maxWidth:  445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/premium-vector/office-worker-wearing-glasses_277909-81.jpg?size=626&ext=jpg&ga=GA1.1.1053072546.1708775690&semt=ais'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Imran Basha
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Excellent results I'm very happy with the consultation, Dr Riyaz is very experienced and friendly.. he made me very comfortable to express my problems.. he is definately best sexologist in Bangalore his herbal medication have no side effect at all but working very well. Thank you Dr. Riyaz.
        </Typography>
      </CardContent>
      <CardActions>
       
      </CardActions>



    </Card>
        <Card sx={{ maxWidth:  445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-848.jpg?size=626&ext=jpg&ga=GA1.1.1053072546.1708775690&semt=ais'}
        title=""
      />
      <CardContent>
      
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Danish
        
        </Typography>
        
        
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        New hope is the best clinic for infertility and its medicines has no side effects i warmly recommend this clinuc for infertility problems thank you dr riyaz doctor.
        </Typography>
       
      </CardContent>
     
      <CardActions>
        
      </CardActions>
    </Card>
    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        
        image={'https://img.freepik.com/premium-vector/man-avatar-profile-round-icon_24640-14044.jpg?w=360'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Md Arshad
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Booking the appointment online was a convenient feature that streamlined the process. On the scheduled Monday visit, a short 5-10 minute wait allowed the efficient processing of my new patient information. The doctor, experienced and compassionate, provided valuable insights and guidance. As I left with a prescription, the entire experience was fast and easy, earning the well-deserved five stars for this professional’s service."
        </Typography>
        <p>   </p>
      </CardContent>
      <CardActions>
       
      </CardActions>
    </Card>
    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        
        image={'https://freesvg.org/img/Cartoon-Man-Avatar-2.png'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Ramesh Naina
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Best sexologist in Bangalore very good Unani treatment for ED erectile disfunction."
        </Typography>
        <p>   </p>
      </CardContent>
      <CardActions>
       
      </CardActions>
    </Card>

    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        
        image={'https://www.cliparts101.com/files/367/63BA654AECB7FD26A32D08915C923030/avatar_nick.png'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Abu Thalib
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Excellent Service a better clinic with best facilities and Experts
Dr.Riyaz was very calm polite listens to all problems and delivers a clear solution for it"
        </Typography>
        <p>   </p>
      </CardContent>
      <CardActions>
       
      </CardActions>
    </Card>


    </div>
    
    {/* <div className='grid md:grid-cols-2 text-center gap-12 px-2'style={{ marginLeft: "40px", marginTop: "30px"  }}>
      <div className="filter-box">
      <h1 className='blog_sub_heading_2 text-left'style={{ marginLeft: "25px"  }}>
    Filter By:
    </h1>
        <ul className="filter-list">
        <li className="filter-item"><FontAwesomeIcon icon={faMars} /> Male Fertility</li>
        <li className="filter-item"><FontAwesomeIcon icon={faVenus} /> Female Fertility</li>
        <li className="filter-item"><FontAwesomeIcon icon={faHospital} /> About New Hope fertility</li>
          
        </ul>
      </div>
    </div> */}
     {/* <h1 className='blog_sub_heading text-left'style={{ marginLeft: "25px" ,marginTop: '70px' }}>
    Useful links 
    </h1>
    <div>
      <p><a href="/erectile-dysfunction-and-treatment-in-bangalore">Newhope Infertility: Erectile Dysfunction and Treatment in Bangalore</a></p>
      <p><a href="/male-erectile-dysfunction-treatment-in-bangalore">Male Erectile Dysfunction Treatment: Causes, Types and Myths</a></p>
      <p><a href="/erectile-impotence-treatment-in-bangalore">Truth Behind Erectile Impotence: Causes, Myths & Solutions</a></p>
      <p><a href="/best-treatment-for-pre-ejaculation-in-bangalore">Exploring the Best Treatment for Pre Ejaculation</a></p>
      <p><a href="/sexologist-in-bangalore">Find the Best Sexologist in Bangalore to Navigate Intimacy</a></p>
      <p><a href="/best-fertility-doctor-in-bangalore">Unlock Fertility Success: Best Fertility Doctor in Bangalore</a></p>
      <p><a href="/walk-in-sexual-health-clinic-in-bangalore">Exploring Walk In Sexual Health Clinics in Bangalore</a></p>
      <p><a href="/male-fertility-clinic-in-bangalore">New Hope Infertility: A Comprehensive Male Fertility Clinic</a></p>
      <p><a href="/sexologist-doctors-near-me-in-bangalore">Navigating Intimacy Wellness: Search “Best Sexologist Doctors Near Me”</a></p>
      <p><a href="/male-sexual-dysfunction-treatment-in-bangalore">Male Sexual Dysfunction Treatment in Bangalore</a></p>
      <p><a href="/ayurvedic-sexologist-near-me-in-bangalore">Unlocking Vitality: Best Ayurvedic Sexologist Near You</a></p>
    </div> */}
    </div> 

  );

}
