// WhoWeAre.js
import React from 'react';
import "./WhoWeAre.css"
import bgImg1 from '../assets/a_hero_bg_05.png';

const Whoweare = () => {
  return (
    <div name='about' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto'>
        <div className='text-left'>
          <h2 className='Who_content_h2_2'>
            Who we are
            <span className='absolute inset-x-0 bottom-0 h-0.5 bg-blue-500 transform origin-left scale-x-0 transition-transform duration-300'></span>
          </h2>
          <p className='text-3xl py-6 text-gray-500 who_font_style'>
          At Newhope Infertility, we epitomize decades of expertise, groundbreaking research, 
          and state-of-the-art technology. However, above all, we are about you.
          </p>

          {/* Additional paragraph */}
          <div className='border-l-4 line pl-4 mb-8 mt-8 font-sans font-montserrat' >
            <p className='text-lg font-medium text-gray-700 mb-2 font-semibold who_font_style'>
            Your well-being, your journey, your kin.
            </p>
            <p className='text-lg text-gray-700 mt-8 who_font_style'>
              Infertility's complexity leads us to address each case uniquely. Our unwavering focus 
              is on you — blending attentive, empathetic, and comprehensive care with an exceptional, 
              tailored regimen of advanced medical interventions.
            </p>
            <p className='text-lg text-gray-700 mt-4 who_font_style'>
              This feat is made achievable through our distinctive collaboration, Helmed by 
              Dr Shaik Riyazuddin 
              Newhope in Fertility emerges as an alliance committed to excellence in reproductive health.
            </p>

            <p className='text-lg text-gray-700 mt-4 who_font_style'>
            Our commitment doesn’t halt there. We understand our duty to aspiring parents and the field of 
            reproductive medicine. We persist in exploring, innovating, and seeking solutions. 
            </p>

            <p className='text-lg text-gray-700 mt-4 who_font_style'>
            Regrettably, despite our efforts, absolute success isn’t always guaranteed.
            </p>

            <p className='text-lg text-gray-700 mt-4 who_font_style'>
            Yet, we pledge to exhaust every resource to maximize your chances of realizing your aspirations."
            </p>
          </div>

        </div>
        <h1 className='Who_content_h2_2'>
            Our history
          </h1>
        <div className='grid md:grid-cols-2 gap-1 px-2 text-center who_font_style'>

            <p className='text-lg text-gray-700 mt-8'>
            Newhope infertility is working Generations together for wellness of humanity, 
            it starts from 1969 by my Father Late Hakeem Shaik Ziauddin, with the help of our forefather’s 
            hereditary formulas and bookish knowledge, he developed the clinic, and his patients Allover from Tamilnadu Karnataka 
             and Andhra Pradesh, frequently he or they visited together for treatment.My Father and 
             My Uncle ( My Father’s younger brother ) Dr, Shaik Khamruddin ‘MD’  Senior Medical Officer and Drug Inspector in Hyderabad are My inspirators to become a doctor, I passed My Under Graduation from GOVT UNANI MEDICAL COLLEGE it is Ancient Medical college from NIZAM’S period, Greatest college of all time NIZAMIYA TIBBI COLLEGE in Hyderabad I got the Bachelor Degree for Unani Medicine and Surgery, After surgery I did PG Diploma in Emergency Medicine from CARE HOSPITAL Institute Banjara Hills Hyderabad,
            Now I Dr Shaik Riyazuddin Very Happy for taking an opportunity of introduce our srevices Online Alhamdulillah.
            </p>
            <div className='w-full transform hover:translate-x-2 transition duration-300 ease-in-out'>
            <img className='w-full' src={bgImg1} alt="/" />
          </div>

            {/* <p className='text-lg text-gray-700 mt-4'>
              This feat is made achievable through our distinctive collaboration. Helmed by Dr. [Doctor's Name], New Hope in Fertility emerges as an alliance committed to excellence in reproductive health.
            </p> */}
        </div>
      </div>
    </div>
  );
};

export default Whoweare;





// who we are color - : linear-gradient(to right, #006fbe, #00a4e3 70%);