import Footer from '../Footer';
import Navbar from '../Navbar';
import Topic10 from '../blog/topic10';


function App() {
  return (
    <>
      <Navbar />
      <Topic10 />
      <Footer />
    </>
  );
}

export default App;