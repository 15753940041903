import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import bgImg1 from '../assets/female_fertility.jpg';
import bgImg2 from '../assets/male_fertility.jpg';
import bgImg3 from '../assets/doctor_img.jpg';
import "./BlogCards.css"




export default function MediaCard() {
  return (
    <div className='text-center' style={{marginBottom:'80px'}}>
    <h1 className='blog_sub_heading text-left'style={{ marginLeft: "25px"  }}>
    Blog
    </h1>
    <div className='grid md:grid-cols-3 gap-12 px-2'style={{ marginLeft: "0px", marginTop: "30px"  }}>

    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        
        image={'https://sa1s3optim.patientpop.com/assets/images/provider/photos/2685608.jpg'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Newhope Infertility for Erectile Dysfunction and Treatment in Bangalore
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Erectile Dysfunction (ED) is a medical condition     characterized by the consistent inability to achieve or maintain an erection sufficient for sexual intercourse.                      
        </Typography>
        <p>   </p>
      </CardContent>
      <CardActions>
        <a href="/erectile-dysfunction-and-treatment-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>



    <Card sx={{ maxWidth:  445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={bgImg2}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Male Erectile Dysfunction Treatment: Causes, Types and Myths
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Male erectile dysfunction (ED) is a pervasive issue that affects men of various ages, challenging not only their physical health but also impacting psychological well-being and relationships.
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/male-erectile-dysfunction-treatment-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>



    </Card>
        <Card sx={{ maxWidth:  445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={bgImg3}
        title=""
      />
      <CardContent>
      
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Erectile Impotence Treatment in Bangalore: Causes, Myths and Solutions 
        
        </Typography>
        
        
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Erectile impotence, also known as erectile dysfunction (ED), refers to the consistent inability to achieve or maintain an erection sufficient for satisfactory sexual performance.
        </Typography>
       
      </CardContent>
     
      <CardActions>
        <a href="/erectile-impotence-treatment-in-bangalore">
    
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>



    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/free-photo/portrait-woman-with-mgiraine-girl-grimaces-from-painful-headache-touches-her-head-frowns-from_1258-199737.jpg?w=1380&t=st=1711636081~exp=1711636681~hmac=d11797d0abefa0ce2bd45a7d9098efd899fbd308338d98e04b7f9fb47eb89929'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Exploring the Best Treatment for Pre Ejaculation
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Premature Ejaculation (PE) is a common sexual dysfunction characterized by ejaculation occurring sooner than desired, causing distress. 
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/best-treatment-for-pre-ejaculation-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/free-photo/bad-sex-concept-with-upset-couple_23-2149071007.jpg?t=st=1711635927~exp=1711639527~hmac=44e304d6dba20ed2ba25dab0ebccbda87ad2d391f5983673d6067d423047782c&w=900'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Navigate Intimacy: Find the Best Sexologist in Bangalore
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Sexual health is a fundamental aspect of overall well-being, contributing significantly to an individual's physical, emotional, and social health.
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/sexologist-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={bgImg1}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Unlock Fertility Success: Best Fertility Doctor in Bangalore
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Embarking on the journey of parenthood is an exciting yet challenging chapter in many couples' lives.
        </Typography>
      </CardContent>
      <p>   </p>
      <CardActions>
        <a href="/best-fertility-doctor-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://www.sextherapy.ie/assets/images/therapy/IntimacyIssues.jpg'}
        title=""
      />
      <CardContent>
      <p>   </p>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Exploring Walk In Sexual Health Clinic in Bangalore
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Sexual health is an integral aspect of overall well-being, and timely access to healthcare services is crucial in addressing various concerns. 
        </Typography>
        
      </CardContent>
      <p>   </p>
      <CardActions>
        <a href="/walk-in-sexual-health-clinic-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/free-photo/team-medical-experts-with-face-masks-hospital-during-coronavirus-pandemic_637285-11188.jpg'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        New Hope Infertility: A Male Fertility Clinic in <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>Bangalore </Typography>
        </Typography>
        <p>   </p>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Are you or your partner facing challenges in conceiving? Fertility concerns can be overwhelming, but the good news is that help is available. 
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/male-fertility-clinic-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/free-photo/doctor-talking-patient-side-view_23-2149856213.jpg'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Navigating Intimacy Wellness: Search “Best Sexologist Doctors Near Me”
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        In the bustling city of Bangalore, where the pace of life can be relentless, the need for comprehensive and compassionate healthcare is more critical than ever. 
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/sexologist-doctors-near-me-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://img.freepik.com/free-photo/female-doctor-her-office-with-copy-space_23-2148947632.jpg?t=st=1711645753~exp=1711649353~hmac=0cbdf328947366705900f5c6cc03838a21402b5adf01e743fcd93996c9eaaffc&w=1060'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Male Sexual Dysfunction Treatment in<Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}> Bangalore</Typography>
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Male sexual dysfunction involves various challenges that can disrupt the normal sexual response cycle, impacting different aspects of sexual function. 
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/male-sexual-dysfunction-treatment-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>


    <Card  sx={{ maxWidth: 445 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={'https://zanducare.com/cdn/shop/articles/Zanducare_Template_3_800x.jpg?v=1695642812'}
        title=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Comfortaa, Arial, Helvetica, sans-serif' }}>
        Unlocking Vitality: Best Ayurvedic Sexologist Near Me in Bangalore
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial, Helvetica, sans-serif' }}>
        Ayurvedic Sexology is a holistic approach to sexual health rooted in Ayurveda, an ancient system of medicine from India. 
        </Typography>
      </CardContent>
      <CardActions>
        <a href="/ayurvedic-sexologist-near-me-in-bangalore">
      
      <button class="glowing-button">Read more</button>
      </a>
      </CardActions>
    </Card>

    </div>
    
    {/* <div className='grid md:grid-cols-2 text-center gap-12 px-2'style={{ marginLeft: "40px", marginTop: "30px"  }}>
      <div className="filter-box">
      <h1 className='blog_sub_heading_2 text-left'style={{ marginLeft: "25px"  }}>
    Filter By:
    </h1>
        <ul className="filter-list">
        <li className="filter-item"><FontAwesomeIcon icon={faMars} /> Male Fertility</li>
        <li className="filter-item"><FontAwesomeIcon icon={faVenus} /> Female Fertility</li>
        <li className="filter-item"><FontAwesomeIcon icon={faHospital} /> About New Hope fertility</li>
          
        </ul>
      </div>
    </div> */}
     {/* <h1 className='blog_sub_heading text-left'style={{ marginLeft: "25px" ,marginTop: '70px' }}>
    Useful links 
    </h1>
    <div>
      <p><a href="/erectile-dysfunction-and-treatment-in-bangalore">Newhope Infertility: Erectile Dysfunction and Treatment in Bangalore</a></p>
      <p><a href="/male-erectile-dysfunction-treatment-in-bangalore">Male Erectile Dysfunction Treatment: Causes, Types and Myths</a></p>
      <p><a href="/erectile-impotence-treatment-in-bangalore">Truth Behind Erectile Impotence: Causes, Myths & Solutions</a></p>
      <p><a href="/best-treatment-for-pre-ejaculation-in-bangalore">Exploring the Best Treatment for Pre Ejaculation</a></p>
      <p><a href="/sexologist-in-bangalore">Find the Best Sexologist in Bangalore to Navigate Intimacy</a></p>
      <p><a href="/best-fertility-doctor-in-bangalore">Unlock Fertility Success: Best Fertility Doctor in Bangalore</a></p>
      <p><a href="/walk-in-sexual-health-clinic-in-bangalore">Exploring Walk In Sexual Health Clinics in Bangalore</a></p>
      <p><a href="/male-fertility-clinic-in-bangalore">New Hope Infertility: A Comprehensive Male Fertility Clinic</a></p>
      <p><a href="/sexologist-doctors-near-me-in-bangalore">Navigating Intimacy Wellness: Search “Best Sexologist Doctors Near Me”</a></p>
      <p><a href="/male-sexual-dysfunction-treatment-in-bangalore">Male Sexual Dysfunction Treatment in Bangalore</a></p>
      <p><a href="/ayurvedic-sexologist-near-me-in-bangalore">Unlocking Vitality: Best Ayurvedic Sexologist Near You</a></p>
    </div> */}
    </div> 

  );

}
