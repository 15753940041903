import Footer from '../Footer';
import Navbar from '../Navbar';
import Topic8 from '../blog/topic8';


function App() {
  return (
    <>
      <Navbar />
      <Topic8 />
      <Footer />
    </>
  );
}

export default App;