import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const heroStyle = {
    
marginBottom: '50px',
marginLeft: '50px',
    marginTop: '50px',
    marginRight: '50px',
    
  };


function FormFloatingLayoutExample() {
  return (
  
    <div style={heroStyle}>
        {/*row1*/}
    <Row className="g-2 p-3">
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Patient name">
          <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Mobile number">
          <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>
      </Col>
    </Row>

     {/*row2*/}
     <Row className="g-2 p-3">
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Email address">
          <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Select gender"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select gender</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
            <option value="3">Others</option>
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>

    {/*row3*/}
    <Row className="g-2 p-3">
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Your age">
          <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Martial status"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select martial status </option>
            <option value="1">Married</option>
            <option value="2">Single</option>
            <option value="3">Divorced</option>
            <option value="4">Separated</option>
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>

    {/*row4*/}
    <Row className="g-2 p-3">
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Your address">
          <Form.Control type="address" placeholder="" />
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Have children"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select children </option>
            <option value="1">Yes</option>
            <option value="2">No</option>
           
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>

    {/*row5*/}
    <Row className="g-2 p-3">
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Height (in cm)">
          <Form.Control type="height" placeholder="" />
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Weight (in kg)">
          <Form.Control type="weight" placeholder="" />
        </FloatingLabel>
      </Col>
    </Row>

    {/*row6*/}
    <Row className="g-2 p-3">
    <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Body type"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select body type </option>
            <option value="1">Slim</option>
            <option value="2">Average</option>
            <option value="3">Above average</option>
            <option value="4">Athletic</option>
            <option value="5">A few extra kilos</option>
            <option value="6">Large/Heavy</option>
           
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Diet"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select diet </option>
            <option value="1">Veg</option>
            <option value="2">Non-veg</option>
            <option value="2">Occasionally non-veg</option>
           
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>

     {/*row7*/}
     <Row className="g-2 p-3">
    <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Sleep"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select sleep </option>
            <option value="1">Disturbed</option>
            <option value="2">UnTimely</option>
            <option value="3">Timely</option>
            <option value="4">Sound</option>
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Bowels habit"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select bowels habit </option>
            <option value="1">Normal</option>
            <option value="2">Constipated</option>
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>

     {/*row8*/}
     <Row className="g-2 p-3">
    <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Smoke"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select smoke </option>
            <option value="1">Yes</option>
            <option value="2">No</option>
            <option value="3">Ocassionally</option>
            <option value="4">Not now</option>
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label="Drink"
        >
          <Form.Select aria-label="Floating label select example">
            <option>Open to select drink </option>
            <option value="1">Yes</option>
            <option value="2">No</option>
            <option value="3">Ocassionally</option>
            <option value="4">Not now</option>
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Row>


 {/*row9*/}
    <Row className="g-2 p-3">
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Short history of present complaint">
          <Form.Control type="height" placeholder="" />
        </FloatingLabel>
      </Col>
      <Col md>
        <FloatingLabel controlId="floatingInputGrid" label="Any other disease you've been suffering from">
          <Form.Control type="weight" placeholder="" />
        </FloatingLabel>
      </Col>
    </Row>
    <p>*CONSULTATION DETAILS ARE KEPT CONFIDENTIAL</p>
    <Button   style={{  marginTop: '20px', alignItems:'center' }} >Submit</Button>{' '}
    </div>
    
   
  
  );
}

export default FormFloatingLayoutExample;