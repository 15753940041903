import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgImg1 from '../assets/male_fertilite_bg_1.png';
import bgImg2 from '../assets/male_fertility_img_dr_1.png';
import "./MaleFertilityContent.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';

const MaleFertilityContent = () => {

  return (
    <div className='my-32'>
    <h1 className='male_con_sub_heading' style={{ marginLeft: "20px"  }}>
     Surgical sperm retrieval
  </h1>
    <div className='grid md:grid-cols-3 gap-1 px-2 text-left who_font_style'>

    <p className='text-lg text-gray-700 mt-16' style={{ marginLeft: "20px"  }}>
    For couples struggling with fertility, semen analysis tests are routine at our London clinic. These tests provide 
    a sperm count, indicating the volume of sperm in a man’s ejaculate. Azoospermia, the absence of sperm in the ejaculate,
     may occur for various reasons. In some cases, it's due to obstruction, like a past vasectomy or infections, 
     where healthy sperm exists but cannot reach the egg. Alternatively, it can result from testicular production issues, 
     hormonal imbalances, or difficulties with ejaculation, stemming from spinal cord injuries or 
    psychological factors. Azoospermia's causes vary, affecting the presence or accessibility of sperm in different ways.
    </p>
    <div className='w-full transform hover:translate-x-2 transition duration-300 ease-in-out'style={{ marginRight: "20px"  }}>
    <img className='w-full' src={bgImg1} alt="/" />
  </div>

    <p className='text-lg text-gray-700 mt-12'>
    If a zero sperm count/azoospermia is detected, the andrology specialists at King’s Fertility will be able to arrange 
    any further tests that may be required, and discuss any treatment and next-step options with you. Finding out your sperm 
    count is extremely low or zero can 
    feel very alarming but in many cases there are things we can do to help, including surgical sperm retrieval (SSR).<br />
    A number of different surgical sperm retrieval procedures are available, depending on the underlying cause of 
  your azoospermia. Generally, the aim of SSR is to surgically extract sperm so that it can be used for fertility treatment.
    </p>
    </div>

    <h1 className='male_con_sub_heading text-left' style={{ marginLeft: "20px" , fontSize: "60px" }}>
    How can i help you
    </h1>
    <div className='grid md:grid-cols-1 gap-1 px-2 text-left who_font_style'>
      <p className='text-lg text-gray-700 mt-16'style={{ marginLeft: "20px" }}>
      According to a BBC article, researchers found that sperm counts among men from North America, Europe, Australia 
      and Asia appear less in 40 years.<br />So the scientific evidence is there to show a rising number of men experiencing
       issues with fertility. Rather than starting IVF, ICSI (intracytoplasmic<br /> sperm injection), rushing into drug therapy, 
       hormonal therapy or an invasive surgical procedure, the Traditional UNANI Herbal Medicine approach<br /> takes into 
       account underlying causes to any symptoms you may have noticed. 
      </p>
    </div>
    {/* <h1 className='male_con_sub_heading' style={{ marginLeft: "20px", marginTop: "20px"  }}>
     Surgical sperm retrieval
      </h1>
    <div className='grid md:grid-cols-2 gap-1 px-2 text-left who_font_style'>
        <p className='text-lg text-gray-700 mt-8' style={{ marginLeft: "20px" }}>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Premature ejaculation</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px"  }}>Erectile dysfunction ED</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Low sperm count (oligospermia)</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Low Male libido or reduced sexual desire</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Spermatorrhoea</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Low testosterone levels (Hypogonadism)</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Male Sexual Hormonal imbalance</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Complete absence of sperm (azoospermia)</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Abnormal sperm shape (teratozoospermia)</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Problems with sperm movement (asthenozoospermia)</b> <br/>
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#11cbff" }}/> <b style={{ marginLeft: "10px", fontSize: "18px" }}>Sperm that is completely immobile (necrozoospermia)</b> <br/>
        </p>
        <div className='w-full transform hover:translate-x-2 transition duration-300 ease-in-out'style={{ marginRight: "20px", marginTop: "-100px"  }}>
        <img className='w-full' src={bgImg2} alt="/" />
      </div>
      </div> */}
      <div>
    <h1 className='male_con_sub_heading' style={{ marginLeft: "20px", marginTop: "20px"  }}>
     Best Sexologist in Bangalore
      </h1>
    <div className='grid md:grid-cols-2 gap-1 px-2 text-left who_font_style'>
      <div>
   
    <Accordion style={{marginBottom :'80px'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{ color: '#341E93'}}>1. Premature Ejaculation</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>
        Premature Ejaculation  (PE) is a condition where a man Ejaculates sooner than he or his Partner  would like During Sexual Activity. However, when it occurs Frequently and causes Distress or Relationship Problems, it may be Diagnosed as PE. It can be caused by Physical factors such as Hormonal Imbalances or Hypersensitivity of the penis. Treatment options include Behavioral techniques and Medications.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="1">
        <Accordion.Header>2. Erectile dysfunction ED</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>
        Erectile Dysfunction (ED) is a condition Characterized by the inability to achieve or  maintain an erection  firm enough for Sexual intercourse. It can be caused by various Factors such as Physical issues, Psychological Factors, or lifestyle choices. Treatment Options include Medications ,lifestyle Changes ,and Therapy. 
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="2">
        <Accordion.Header>3. Low sperm count (oligospermia)</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Oligospermia is Low Sperm Count in Semen, Potentially causing Fertility issues. It can result from Various factors like Hormonal Imbalances or lifestyle Choices. Treatment depends on the cause and may Involve Lifestyle Changes, Medication, or Assisted Reproductive Techniques.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="3">
        <Accordion.Header>4. Low Male libido or reduced sexual desire</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Male low libido, or Hypoactive Sexual Desire Disorder (HSDD), is a Persistent lack of interest in Sexual activity causing Distress,. It can be due to Physical Factors like Hormonal Imbalances or Psychological Factors such as Stress., Treatment Depends on the cause and may Include Medication, Therapy, or lifestyle Changes.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="4">
        <Accordion.Header>5. Low testosterone levels (Hypogonadism)</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Testosterone is a hormone essential for male development, responsible for traits like muscle mass and sex drive. Levels peak in early adulthood and decline with age. Low levels can lead to symptoms like reduced libido and fatigue.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="5">
        <Accordion.Header>6. Spermatorrhea</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Spermatorrhea is the involuntary or Excessive Ejaculation of Semen without Sexual Arousal. It can happen During Sleep or waking hours and may be caused by Physical or Psychological Factors. Treatment Depends on the cause and may include Medications or Therapy.</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="6">
        <Accordion.Header>7. Azoospermia </Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Azoospermia is a condition where there are no sperm present in the semen. It can be due to a blockage preventing sperm from mixing with semen or issues with sperm production in the testicles. It can lead to infertility and may require medical intervention for conception.

</Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="7">
        <Accordion.Header >8. Problems with sperm movement (asthenozoospermia)</Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#EEEEEE'}}>Asthenozoospermia, also known as asthenospermia, is a condition that causes low sperm motility, which can impact fertility. It's defined as less than 40% sperm motility, or less than 32% progressive motility. Sperm motility is one of the key parameters assessed in a standard semen analysis to evaluate male fertility.</Accordion.Body>
        </Accordion.Item>
        
    </Accordion>
    <div className='w-full transform hover:translate-x-2 transition duration-300 ease-in-out'style={{ marginRight: "20px", marginTop: "-60px"  }}>
        <img className='w-full' src={bgImg2} alt="/" />
      </div>
    </div>
    </div>
    </div>
  

      <div className='grid md:grid-cols-2 gap-1 px-2 text-left who_font_style'>
        <div style={{ marginRight: "20px" }}>
        <h1 className='male_con_sub_heading' style={{ marginLeft: "20px"  }}>
        Treatments
        </h1>
        <p className='text-lg text-gray-700 mt-8' style={{ marginLeft: "20px" }}>
        UNANI herbal medicine has promoted health and wellbeing with remedies using active ingredients taken from the 
        natural world of herbs and plants.I trained as a classical Sexologist/Psychologist herbalist, learned about the 
        propertiesof hundreds of different herbs, and medical pharmacology in order to understand interactions and keep 
        clients safe and well.
        </p>
        <p className='text-lg text-gray-700 mt-8' style={{ marginLeft: "20px" }}>
        I use UNANI Medicine for my clients, myself and my family. Rich in plants, twigs, barks, grasses, flowers and bulbs, 
        my herbal preparations are guaranteed to be free of pesticides, sulphides, xeno-oestrogens and steroids.
        When I make a formula, it is tailored specifically to you; so no two people will have the same prescription. 
        I sit down quietly with your notes in front of me, and customize exactly what I feel you need. Sometimes 
        I may prescribe one formula for a whole month; often there are different formulae for specific days or weeks. 
        I’ll always explain why and how these preparations work. People often think of my herbs as little bottles of true magic!
        </p>
        </div>
        <div style={{ marginRight: "20px" }}>
        <h1 className='male_con_sub_heading' style={{ marginLeft: "20px"  }}>
          Your first appointment
        </h1>
        <p className='text-lg text-gray-700 mt-8' style={{ marginLeft: "20px" }}>
        During yourking you questions about your lifestyle, stress levels and nutrition. It can be very helpful for the 
        person who will be supporting you through treatment to come along to this first meeting; they’ll be made very welcome.  
        Please also bring any information you may have about your condition plus any investigation or blood test results
         you’ve had.
        </p>
        </div>
      </div>
    </div>

  );
};



export default MaleFertilityContent;